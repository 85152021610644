// SimilarPosts.js
import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from "gatsby"
import { SimilarPostsFactory } from './SimilarPostsFactory'
import CardMini from '../components/CardMini'
import { Grid } from 'semantic-ui-react'

const SimilarPostsComponent = ({ posts }) => (
  <Grid id="similar-posts">
    {posts.map(({post}, i) => (
      <CardMini key={post.node.id} {...post.node} />
    ))}
  </Grid>
)

// (1.) Query for posts
export default (props) => (
  <StaticQuery query={graphql`
      query {
        allContentfulPost(sort: {fields: [publishDate], order: DESC}, limit: 1000) {
          edges {
            node {
              id
              title
              slug
              tags {
                id
                title
                slug
              }
              categories {
                id
                title
                slug
                color
              }
              publishDate(formatString: "MMMM DD, YYYY")
              body {
                childMarkdownRemark {
                  timeToRead
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      // (1.) Pass in current post tags and slug
      const { tags, categories, currentPostSlug } = props;

      // (2.) Marshall the response into posts
      const posts = data.allContentfulPost.edges;

      // (3.) Use a SimilarPostsFactory to get my similar posts
      const similarPosts = new SimilarPostsFactory(
        posts, currentPostSlug
      )
      .setMaxPosts(3)
      .setCategory(categories[0])
      .setTags(tags)
      .getPosts()

      // (4.) Render it
      return (
        <SimilarPostsComponent
          posts={similarPosts}
        />
      )
    }}
  />
)
