// SimilarPostsFactory.js
import { includes, orderBy } from 'lodash'

export class SimilarPostsFactory {
  // (1.) Create by passing in posts, currentSlug
  constructor (posts, currentPostSlug) {
    // (2.) Don't include the current post in posts list
    this.posts = posts.filter(
      (aPost) => aPost.node.slug !== currentPostSlug);
    this.currentPostSlug = currentPostSlug;
    // (3.) Set default values
    this.maxPosts = 3;
    this.category = null;
    this.tags = [];
  }

  // (4.) Builder pattern usage
  setMaxPosts (m) {
    this.maxPosts = m;
    return this;
  }

  setCategory (aCategory) {
    this.category = aCategory;
    return this;
  }

  setTags (tagsArray) {
    this.tags = tagsArray;
    return this;
  }

  getPosts () {
    const { category, tags, posts, maxPosts } = this;
    // (5.) We use an Identity Map to keep track of score
    const identityMap = {};

    if (!!tags === false || tags.length === 0) {
      console.error('SimilarPostsFactory: Tags not provided, use setTags().')
      return [];
    }

    if (!!category === false) {
      console.error('SimilarPostsFactory: Category not provided, use setCategory().')
      return [];
    }

    function getSlug (post) {
      return post.node.slug;
    }

    function addToMap (post) {
      const slug = getSlug(post);
      if (!identityMap.hasOwnProperty(slug)) {
        identityMap[slug] = {
          post: post,
          points: 0
        }
      }
    }

    // (7.) For category matches, we add 2 points
    function addCategoryPoints (post, category) {
      const categoryPoints = 2;
      const slug = getSlug(post);

      if (post.node.categories[0].slug === category.slug) {
        identityMap[slug].points += categoryPoints;
      }
    }

    // (8.) For tags matches, we add 1 point
    function addTagsPoints (post, tags) {
      const tagPoint = 1;
      const slug = getSlug(post);

      post.node.tags.forEach((aTag) => {
        tags.forEach((tag) => {
          if (includes(tag, aTag.slug)) {
            identityMap[slug].points += tagPoint;
          }
        })
      })
    }

    function getIdentityMapAsArray () {
      return Object.keys(identityMap).map((slug) => identityMap[slug]);
    }
    
    // (6.) Map over all posts, add to map and add points
    for (let post of posts) {
      addToMap(post);
      addCategoryPoints(post, category);
      addTagsPoints(post, tags)
    }
    
    // (9.) Convert the identity map to an array
    const arrayIdentityMap = getIdentityMapAsArray();

    // (10.) Use a lodash utility function to sort them 
    // by points, from greatest to least
    const similarPosts = orderBy(
      arrayIdentityMap, ['points'], ['desc']
    )
    
    // (11. Take the max number posts requested)
    return similarPosts.splice(0, maxPosts);
  }
}
