import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import MaxWidth from '../components/MaxWidth'
import PageBody from '../components/PageBody'
import TagList from '../components/TagList'
import PostLinks from '../components/PostLinks'
import PostDetails from '../components/PostDetails'
import Subscribe from '../components/Subscribe'
import SimilarPosts from '../components/SimilarPosts'
import ShareButtons from '../components/ShareButtons'
import SEO from '../components/SEO'
import { Grid, Button, Icon } from 'semantic-ui-react'

const PostTemplate = ({ data, pageContext }) => {
  const {
    id,
    title,
    slug,
    body,
    publishDate,
    categories,
    tags,
  } = data.contentfulPost
  const postNode = data.contentfulPost
  const previous = pageContext.prev
  const next = pageContext.next
  const url = typeof window !== 'undefined' ? window.location.href : ''
  const category = categories && categories[0].title

  return (
    <Layout pageID="post">
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} postSEO />
      <Grid centered id="post-header" className={`${category.toLowerCase()}`}>
        <Grid.Column mobile={10} tablet={8} computer={6}>
          <div id="post-title">
            <h1>{title}</h1>
          </div>
        </Grid.Column>
      </Grid>
      <MaxWidth>
        <Grid padded>
          <Grid.Column mobile={12} tablet={4} computer={3}>
            <ShareButtons
              url={url}
              title={body.title}
              description="something"
            />
            <PostDetails
              publishDate={`Published ${publishDate}`}
              timeToRead={body.childMarkdownRemark.timeToRead}
            />
            <div id="post-categories">
              {categories.map(category => (
                <Button
                  fluid
                  icon
                  labelPosition="right"
                  key={category.id}
                  color={category.color}
                  as={Link}
                  to={`/category/${category.slug}/`}
                  animated="vertical"
                >
                  <Icon name="right arrow" />
                  <Button.Content visible>{category.title}</Button.Content>
                  <Button.Content hidden>learn more</Button.Content>
                </Button>
              ))}
            </div>
            {tags && <TagList tags={tags} />}
          </Grid.Column>
          <Grid.Column
            floated="right"
            mobile={12}
            tablet={8}
            computer={8}
          >
            <PageBody body={body} />
            <h3>Related articles...</h3>
            <SimilarPosts
              currentPostSlug={slug}
              tags={tags}
              categories={categories}
            />
          </Grid.Column>
        </Grid>
      </MaxWidth>
      <section className="page-subscribe">
        <Subscribe />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      id
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      categories {
        title
        id
        slug
        color
      }
      tags {
        title
        id
        slug
      }
      body {
        childMarkdownRemark {
          timeToRead
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default PostTemplate
