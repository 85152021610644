import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-plugin-image'
import { Grid, Segment } from 'semantic-ui-react'
import TagList from '../components/TagList'
import PostDetails from '../components/PostDetails'

const CardMini = ({
  slug,
  title,
  categories,
  publishDate,
  body: {
    childMarkdownRemark: { timeToRead },
  },
  ...props
}) => {
  return (
    <Grid.Column mobile={12} tablet={6} computer={6} className="card mini">
      <Link to={`/${slug}/`}>
        <div className={`card-content ${categories && categories[0].color}`}>
          <h4>{categories && categories[0].title}</h4>
          <h2>{title}</h2>
          <div className="card-meta">
            <PostDetails timeToRead={timeToRead} />
          </div>
        </div>
      </Link>
    </Grid.Column>
  )
}

export default CardMini
